<template v-if="this.hasView === true">
  <div class="wrap-list-platform">
    <CRow>
      <CCol class="col--padding">
        <CCard>
          <CCardBody>
            <label class="title-items">{{$t("LIST_PLATFORM_PAGE_TITLE_STAKING_PLATFORM")}}</label>
            <div class="overflow-table">
              <table class="table table-striped table-drag">
                <thead class="thead-white">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{$t("LIST_PLATFORM_PAGE_LABEL_STATUS")}}</th>
                    <th scope="col">{{$t("LIST_PLATFORM_PAGE_LABEL_PLATFORM")}}</th>
                    <th scope="col">{{$t("LIST_PLATFORM_PAGE_LABEL_CURRENCY_NAME")}}</th>
                    <th scope="col">{{$t("LIST_PLATFORM_PAGE_LABEL_CURRENCY_CODE")}}</th>
                    <th scope="col">{{$t("LIST_PLATFORM_PAGE_LABEL_REWARDS")}}</th>
                    <th>{{$t("LIST_PLATFORM_PAGE_LABEL_LOCK_UP")}}</th>
                    <th class="text-center"  v-if="this.hasUpdate === true">{{$t("LIST_PLATFORM_PAGE_LABEL_ACTION")}}</th>
                  </tr>
                </thead>
                <Draggable v-model="nativeList" v-bind="dragOptions" tag="tbody" handle=".handle">
                  <tr v-for="(item, index) in nativeList" :key="item.id" class="t-body" v-if="item.staking_type === 'NATIVE'">
                    <td scope="row">{{ index + 1}}</td>
                    <td class="check-status">
                      <span v-if="item.status === 0" class="coming-soon"><img src="@/assets/images/coming-soon.svg"/>{{$t("LIST_PLATFORM_PAGE_TEXT_COMING_SOON")}}</span>
                      <span v-else-if="item.status === -1" class="disabled"><img src="@/assets/images/disabled.svg"/>{{$t("LIST_PLATFORM_PAGE_TEXT_DISABLED")}}</span>
                      <span v-else-if="item.status === 1" class="active"><img src="@/assets/images/ico-user-active.svg"/>{{$t("LIST_PLATFORM_PAGE_TEXT_ENABLED")}}</span>
                      <span v-else class="hidden"><img src="@/assets/images/hidden.svg"/>{{$t("LIST_PLATFORM_PAGE_TEXT_HIDDEN")}}</span>
                    </td>
                    <td>
                      <!--<CButton color="link" class="row" @click="goDetails(item)">-->{{ item.platform }}<!--</CButton>-->
                    </td>
                    <td class="platform-item-ico"><img :src="item.icon" alt="" /> {{ item.platform }}</td>
                    <td>{{ item.symbol }}</td>
                    <td>{{ roundEstimate(item.estimate_earn_per_year) }}%</td>
                    <td>{{ item.lockup_unvote }} {{ item.lockup_unvote_unit }}</td>
                    <td :class="item.wait_blockchain_confirm_status_flg===false ? 'text-center blue' : 'text-center blue texts-wrap'"  v-if="hasUpdate === true">
                      <CButton @click="editnative(item)" :disabled="item.wait_blockchain_confirm_status_flg==true">{{$t("LIST_PLATFORM_PAGE_BUTTON_EDIT")}}</CButton>
                      <span v-if="item.wait_blockchain_confirm_status_flg!==false">{{$t("LIST_PLATFORM_PAGE_TEXT_PENDING")}}</span>
                    </td>
                  </tr>
                </Draggable>
              </table>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol class="col--padding">
        <CCard>
          <CCardBody>
            <div class="flex1">
              <label class="title-items">{{$t("LIST_PLATFORM_PAGE_TITLE_TOKEN_STAKING_PLATFORM")}}</label>
              <CButton v-if="this.hasAdd === true" @click="goAddnew" class="btn btn-success btn-md btn-new">
                + {{$t("LIST_PLATFORM_PAGE_BUTTON_ADD_TOKEN")}}
              </CButton>
            </div>
            <div class="overflow-table">
              <table class="table table-striped table-drag">
              <thead class="thead-white">
              <tr>
                <th scope="col">#</th>
                <th scope="col">{{$t("LIST_PLATFORM_PAGE_LABEL_STATUS")}}</th>
                <th scope="col">{{$t("LIST_PLATFORM_PAGE_LABEL_PLATFORM")}}</th>
                <th scope="col">{{$t("LIST_PLATFORM_PAGE_LABEL_CURRENCY_NAME")}}</th>
                <th scope="col">{{$t("LIST_PLATFORM_PAGE_LABEL_CURRENCY_CODE")}}</th>
                <th scope="col">{{$t("LIST_PLATFORM_PAGE_LABEL_REWARDS")}}</th>
                <th>{{$t("LIST_PLATFORM_PAGE_LABEL_DURATION")}}</th>
                <th class="text-center" v-if="this.hasUpdate === true">{{$t("LIST_PLATFORM_PAGE_LABEL_ACTION")}}</th>
              </tr>
              </thead>
              <Draggable v-model="ercList" v-bind="dragOptions" tag="tbody" handle=".handle">
                <tr v-for="(item, index) in ercList" :key="item.id" class="t-body" v-if="item.staking_type !== 'NATIVE'">
                  <td scope="row">{{ index + 1}}</td>
                  <td class="check-status">
                    <span v-if="item.status === 0" class="coming-soon"><img src="@/assets/images/coming-soon.svg"/>{{$t("LIST_PLATFORM_PAGE_TEXT_COMING_SOON")}}</span>
                    <span v-else-if="item.status === -1" class="disabled"><img src="@/assets/images/disabled.svg"/>{{$t("LIST_PLATFORM_PAGE_TEXT_DISABLED")}}</span>
                    <span v-else-if="item.status === 1" class="active"><img src="@/assets/images/ico-user-active.svg"/>{{$t("LIST_PLATFORM_PAGE_TEXT_ENABLED")}}</span>
                    <span v-else class="hidden"><img src="@/assets/images/hidden.svg"/>{{$t("LIST_PLATFORM_PAGE_TEXT_HIDDEN")}}</span>
                  </td>
                  <td>
                    <!--<CButton color="link" class="row" @click="goDetails(item)">-->{{ item.platform }}<!--</CButton>-->
                  </td>
                  <td class="platform-item-ico"><img :src="item.icon" alt="" /> {{ item.name }}</td>
<!--                  <td><img :src="item.icon" alt="" class="img-icon" />{{item.name}}</td>-->
                  <td>{{ item.symbol }}</td>
                  <td>{{ item.erc20_reward_estimate }}</td>
                  <td>{{item.erc20_duration}}</td>
                  <td :class="item.wait_blockchain_confirm_status_flg===false ? 'text-center blue' : 'text-center blue texts-wrap'" v-if="hasUpdate === true">
                    <CButton @click="edit(item)" :disabled="item.wait_blockchain_confirm_status_flg==true">{{$t("LIST_PLATFORM_PAGE_BUTTON_EDIT")}}</CButton>
                    <span v-if="item.wait_blockchain_confirm_status_flg!==false">{{$t("LIST_PLATFORM_PAGE_TEXT_PENDING")}}</span>
                  </td>
                </tr>
              </Draggable>
            </table>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Draggable from "vuedraggable";
import endpoints from "@/constants/endpoints";
import {mapActions, mapState} from 'vuex';
import responseHelper from '@/utils/response-helper';
export default {
  components: {
     Draggable
  },
  props: ["id"],
  computed: {
    ...mapState({
       userPermission: state => state.auth.userPermission,
    }),
    offset() {
          return (this.activePage - 1) * this.limit;
    },
    pages() {
          return Math.ceil(this.total / this.limit);
    },
    dragOptions() {
        return {
            animation: 200,
            group: 'description',
            disabled: false,
            ghostClass: 'ghost'
        };
    },
  },
  data() {
    return {
        platforms: [],
        nativeList: [],
        ercList: [],
        limit: 10000,
        activePage:1,
        pageInput:1,
        total:null,
        hasView: false,
        hasAdd: false,
        hasUpdate: false
    };
  },
  async mounted() {
    let data = await this.fetchPlatforms();
    this.platforms=data.items;
    this.total=data.total;
    await this.checkPermission();
  },
  methods: {
    ...mapActions(['getSelectedPlatform']),
    async fetchPlatforms() {
        try {
            const params = {
                limit: this.limit,
                offset: this.offset
            };
            let result = await this.$http.get(
                endpoints.getStakingPlatforms,
                { params }
            );
            if (result.data) {
                const { items } = result.data;
                this.nativeList = items.filter(data => data.staking_type === 'NATIVE');
                this.ercList = items.filter(data => data.staking_type === 'CONTRACT');
                return items
            }
        } catch (err) {
            this.$notify({
                group: "notifications",
                type: "error",
                title: this.$t('LIST_PLATFORM_PAGE_TITLE_NOTIFY_ERROR'),
                text: responseHelper.getErrorMessage(err)
            });
        }

    },
    async pushPage(index) {
      this.activePage = index;
      let platforms = await this.fetchPlatforms();
      if (!platforms) {
        return;
      }
      this.platforms = platforms.items.slice();
    },
    edit(item) {
      this.getSelectedPlatform(item);
      this.$router.push(`staking-platform/erc20/edit-erc20/${item.id}`);
     },
    editnative(item) {
      this.getSelectedPlatform(item);
      this.$router.push(`staking-platform/edit-native/${item.id}`);
    },
    goAddnew(){
        this.$router.push(`staking-platform/erc20/add-erc20`);
    },
    checkPermission(){
        if(this.userPermission.length > 0){
          this.userPermission.forEach(items =>{
            if(items.name === 'CREATE_STAKING_PLATFORM'){
              this.hasAdd = true
            }
            if(items.name === 'UPDATE_STAKING_PLATFORM'){
                this.hasUpdate = true
            }
            if(items.name === 'VIEW_LIST_STAKING_PLATFORM'){
                this.hasView = true
            }
          })
        }
    },
      roundEstimate(value){
          return Number(value).toLocaleString('en-US', {maximumFractionDigits: 4 });
      }
  },
  
};
</script>

<style lang="scss" scoped>
.col--padding{
  padding: 0 24px;
}
.row {
  padding: 0;
  border: 0;
  margin: 0;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
.table-drag {
  border: 1px solid #d8dbe0;
  overflow-x: auto;
}
.overflow-table{
  overflow-x: auto;
}
.t-body {
  /*cursor: grab;*/
  cursor: default;
}

.title-items{
    color: #657187;
    // font-family: "SF UI Display";
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
}
.btn-link{
  // font-family: "SF UI Display";
  font-size: 14px;
  /*font-weight: 300;*/
  /*letter-spacing: 0.5px;*/
  /*color: #6B61E8;*/
    color: #4D5666;
    line-height: 41px;
}
.img-icon{
  width: 20px;
  height: auto;
  margin-right:15px;
}
// thien css
.wrap-list-platform{
  .card-body{
    padding: 15px;
    .flex1{
      display: flex;
      flex: 0 0 100%;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 5px;
      @media screen and ( max-width: 520px ){
        flex-wrap: wrap;
        justify-content: center;
      }
      label{
        color: #657187;
        // font-family: "SF UI Display";
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0;
      }
      .btn-new {
        padding: 0;
        width: 170px;
        height: 32px;
        border-radius: 32px;
        background: #3B4B7E;
        border-color: unset;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        .pr--plus{
          svg{
            margin-top: 0;
          }
        }
      }
    }
    .title-items{
      margin-bottom: 9px;
      padding-left: 5px;
    }
    .table{
      tr{
        color: #657186;
        // font-family: "SF UI Display";
        font-size: 14px;
        letter-spacing: 0.5px;
      }
      thead{
        tr{
          font-weight: 600;
        }
      }
      td{
          color: #4D5666;
          font-size: 14px;
          line-height: 41px;
          vertical-align: inherit;
          /*font-weight: 300;*/
      }
      .blue{
        button{
          color: #5F55E7;
          font-size: 14px;
          line-height: 41px;
        }
      }
      .texts-wrap{
        display: flex;
        flex-direction: column;
        button{
          line-height: 10px;
        }
        span{
          background: #f2f52d;
          color: #000000;
          border-radius: 5px;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
  .check-status{
    img{
      padding-right: 8px;
    }
    .active{
      color: #4CAF50;
    }
    .coming-soon{
      color: #F5A623;
    }
    .disabled{
      color: #FF5858;
    }
    .hidden{
      color: #4A4A4A;
    }
  }
  .platform-item-ico{
    padding-left: 0;
    img{
      width: 20px !important;
      height: 20px !important;
      margin: 0 5px 0 -15px;
    }
  }
}
</style>
